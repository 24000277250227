import React from 'react';
import { Grid, Paper } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

import { mq } from '../breakpoints';
import FullServiceCard from './FullServiceCard';
import Divider from '../assets/divider.svg';
import { Service } from '../types/Service';
import { Special } from '../types/Special';

const DividerWrapper = styled.div`
  margin: 0 auto 32px;
  max-width: 500px;
  position: relative;
  text-align: center;

  ${mq.md(css`
    margin: 32px auto 48px;
  `)}
`;

const divider = css`
  fill: #638b8c;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: none;

  ${mq.md(
    css`
      display: block;
      margin: 0 auto;
    `
  )}
`;

const SpecialText = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  font-size: 18px;
  font-weight: 500;
  padding: 0;
  margin: 0 auto;
  max-width: 400px;

  ${mq.md(css`
    align-items: center;
    bottom: 0;
    color: white;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    justify-content: center;
    max-width: initial;
    padding: 32px;
    position: absolute;
    top: 0;
  `)}
`;

const ServiceWrapper = styled(Paper)`
  background: ${(props) => rgba(props.theme.palette.primary.contrastText, 0.6)};
  border: 1px solid ${(props) => props.theme.palette.primary.main};
  padding: 16px;

  ${mq.md(css`
    padding: 32px;
  `)}
`;

interface PropTypes {
  services: Service[];
  special: Special;
}

function ServicesContainer({ services, special }) {
  return (
    <>
      {special && (
        <DividerWrapper>
          <Divider css={divider} />
          <SpecialText>{special.teaser}</SpecialText>
        </DividerWrapper>
      )}
      <ServiceWrapper elevation={2}>
        <Grid container spacing={4}>
          {services.map((service) => (
            <Grid key={service.slug} item xs={12}>
              <FullServiceCard {...service} />
            </Grid>
          ))}
        </Grid>
      </ServiceWrapper>
    </>
  );
}

export default ServicesContainer;
