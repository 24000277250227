import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import ServicesContainer from '../components/ServicesContainer';

const ServicesPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulServicesPage {
          edges {
            node {
              services {
                ...ServiceFragment
              }
              special {
                teaser
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout pageName='Services'>
        <ServicesContainer
          services={data.allContentfulServicesPage.edges[0].node.services}
          special={data.allContentfulServicesPage.edges[0].node.special}
        />
      </Layout>
    )}
  />
);

export default ServicesPage;
